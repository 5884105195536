import GameTabsStyles from "./styles";
import PlayerIconComponent from "../player-icon.component";
import Avatar from "@mui/material/Avatar";
import SendIcon from "@mui/icons-material/Send";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import AddToGameDialog from "../../core/components/add-to-game.component";
import { useNavigate } from "react-router-dom";
import { usePullToRefresh } from "../../core/context/pull-to-refresh.context";
import { useGame } from "../game.context";

export default function TabPlayers() {
  const {
    game,
    playersWithErrors,
    isCashier,
    isNew,
    isOngoing,
    isSuperAdmin,
    reloadGame,
  } = useGame();

  const navigate = useNavigate();
  const [invitePlayerOpened, setInvitePlayerOpened] = useState<boolean>(false);
  const { setRefreshFunction } = usePullToRefresh();

  const classes = GameTabsStyles();

  useEffect(() => {
    const refreshData = async () => {
      await reloadGame();
    };
    setRefreshFunction(refreshData);
    return () => {
      setRefreshFunction();
    };
  }, [setRefreshFunction]);

  if (!game) return <></>;

  const toggleInvitePlayer = async () => {
    setInvitePlayerOpened(!invitePlayerOpened);
  };

  const onInviteAddClose = async () => {
    setInvitePlayerOpened(false);
    await reloadGame();
    navigate(`/game/${game!.id}/players`);
  };

  return (
    <>
      <div className={`${classes.baseContainer} ${classes.playersContainer}`}>
        {game.players?.map((p, i) => (
          <PlayerIconComponent
            key={i}
            dialogsDisabled={!isCashier && !isSuperAdmin}
            error={playersWithErrors.includes(Number(p.playerId))}
            index={i}
            tablePlayer={p}
            table={game.table!}
            onPlayerStatusChange={reloadGame}
            onPlayerDelete={reloadGame}
            onTransactionSent={reloadGame}
          />
        ))}

        {isCashier && (isNew || isOngoing) && (
          <div className={classes.invitePlayerBtn} onClick={toggleInvitePlayer}>
            <Avatar sx={{ bgcolor: "transparent" }}>
              <SendIcon />
            </Avatar>
            <Typography
              className={classes.invitePlayerText}
              variant="body1"
              gutterBottom
            >
              Invite player
            </Typography>
          </div>
        )}
      </div>
      {(isNew || isOngoing) && (
        <AddToGameDialog
          open={invitePlayerOpened}
          type="invite"
          gameId={game.id!}
          onClose={onInviteAddClose}
        />
      )}
    </>
  );
}
