import GameInterface from '@homegame/common/dist/interface/game.interface';
import { useState } from 'react';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import GameStatus from '@homegame/common/dist/enum/game-status.enum';
import UserInterface from '@homegame/common/dist/interface/user.interface';
import CreateTransactionDialog from '../core/components/create-transaction.component';
import TransactionType from '@homegame/common/dist/enum/transaction-type.enum';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Button from '@mui/material/Button';
import { useAuth } from '../core/context/auth.context';
import useGameInfo from '../core/hooks/useGameInfo/use-game-info.hook';
import TransactionStatus from '@homegame/common/dist/enum/transaction-status.enum';
import PaymentStatus from '../transactions/payment-status.component';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import GamePlayerStats from '../core/hooks/useGameInfo/interface/game-player-balance.interface';
import dayjs from 'dayjs';

const useStyles = makeStyles(() =>
	createStyles({
		listItem: {
			padding: 0,
			minWidth: '100%',
			'& .MuiListItemText-root .MuiTypography-root': {
				color: '#fff',
				fontWeight: 500,
				fontSize: 14,
			},
		},
	})
);

export default function UsersUserOwe({
	game,
	user,
	isDebtsView = false,
	onDebtChanged,
}: {
	game?: GameInterface;
	user?: UserInterface;
	isDebtsView?: boolean;
	onDebtChanged?: () => any;
}) {
	const classes = useStyles();
	const { user: loggedInUser } = useAuth();
	const isFinished = game?.status === GameStatus.FINISHED;
	const [createTransactionVisible, setCreateTransactionVisible] = useState(false);
	const [transactionOptions, setTransactionOptions] = useState<{ playerId: number; amount: number } | undefined>(undefined);
	const { playersGameStats } = useGameInfo(game);
	const gamePlayersBalance = playersGameStats.find((playerInfo) => playerInfo.id === user?.id);

	if (!isFinished || !game || !gamePlayersBalance || loggedInUser?.id !== user?.id) return <></>;

	const isPendingTransactionToUserId = (userId: number) => {
		return game.transactions?.some(
			(t) => t.fromUserId === user?.id && t.toUserId === userId && t.status === TransactionStatus.PENDING && t.type === TransactionType.PAYMENT
		);
	};

	const isAprovedTransactionToUserId = (userId: number) => {
		return game.transactions?.some(
			(t) => t.fromUserId === user?.id && t.toUserId === userId && t.status === TransactionStatus.APPROVED && t.type === TransactionType.PAYMENT
		);
	};

	const onTransactionClose = () => {
		setTransactionOptions(undefined);
		setCreateTransactionVisible(false);
		if (onDebtChanged) onDebtChanged();
	};
	const onTransactionOpen = (playerId: number, amount: number) => {
		setTransactionOptions({ playerId, amount });
		setCreateTransactionVisible(true);
	};

	const showComponent = (isDebtsView && gamePlayersBalance?.totalDebt > 0) || (!isDebtsView && gamePlayersBalance?.debtToTable > 0);

	const getDebtAmountString = (playerStat: GamePlayerStats) => {
		if (isDebtsView) return `${Math.abs(playerStat.totalDebt)} ${game.currency}`;
		return `${Math.abs(playerStat.debtToTable)} ${game.currency}`;
	};

	const getTransactionDate = (playerId: number) => {
		const transaction = game.transactions?.find(
			(t) => t.fromUserId === user?.id && t.toUserId === playerId && t.type === TransactionType.PAYMENT
		);
		if (!transaction) return <></>;
		return <>on {dayjs(transaction.updatedAt).format('DD MMMM YYYY')}</>;
	};
	return (
		<>
			{showComponent && (
				<>
					{!isDebtsView && (
						<>
							<Grid item xs={6} alignSelf="center" m="0 auto">
								<Divider sx={{ mt: 2, mb: 1 }} />
							</Grid>

							<Typography typography="body2" textAlign="center">
								Please select a player to pay to
							</Typography>
						</>
					)}
					<List style={{ maxWidth: !isDebtsView ? '80%' : '100%', margin: !isDebtsView ? '0 auto' : 0 }}>
						{playersGameStats
							.filter((p) => (isDebtsView && p.totalDebt < 0) || (!isDebtsView && p.debtToTable < 0))
							.map((p) => (
								<ListItem
									className={classes.listItem}
									style={{ borderBottom: isDebtsView ? '2px solid rgba(245,0,87,.6)' : 'none' }}
									key={p.id}
								>
									<ListItemText primary={`${p.name}`} secondary={getDebtAmountString(p)} />

									<div style={{ textAlign: 'right' }}>
										{!isAprovedTransactionToUserId(p.id) && !isPendingTransactionToUserId(p.id) && (
											<Button
												variant="contained"
												color="secondary"
												onClick={() => onTransactionOpen(p.id, Math.abs(p.debtToTable))}
											>
												Pay
											</Button>
										)}
										{isPendingTransactionToUserId(p.id) && <PaymentStatus status={TransactionStatus.PENDING} />}
										{isAprovedTransactionToUserId(p.id) && <PaymentStatus status={TransactionStatus.APPROVED} />}

										<small style={{ width: '100%', display: 'block', fontSize: 12 }}>{getTransactionDate(p.id)}</small>
									</div>
								</ListItem>
							))}
					</List>
					{!isDebtsView && (
						<Grid item xs={6} alignSelf="center" m="0 auto">
							<Divider sx={{ mt: 2, mb: 1 }} />
						</Grid>
					)}
				</>
			)}
			<CreateTransactionDialog
				open={createTransactionVisible}
				users={
					game?.players
						?.filter((p) =>
							playersGameStats
								.filter((p) => p.debtToTable < 0)
								.map((t) => t.id)
								.includes(p.playerId as number)
						)
						.map((p) => p.player) as UserInterface[]
				}
				toUser={game?.players?.find((p) => p.playerId === transactionOptions?.playerId)?.player as UserInterface}
				type={TransactionType.PAYMENT}
				game={game}
				amount={transactionOptions?.amount || 0}
				fromUser={user}
				onClose={onTransactionClose}
			/>
		</>
	);
}
