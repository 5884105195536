import { createStyles, makeStyles } from "@mui/styles";
import { useAuth } from "../core/context/auth.context";
import UserInterface from "@homegame/common/dist/interface/user.interface";
import TransactionType from "@homegame/common/dist/enum/transaction-type.enum";
import useGameInfo from "../core/hooks/useGameInfo/use-game-info.hook";
import getPlayerLastTransaction from "../core/hooks/useGameInfo/get-player-last-transaction";
import Typography from "@mui/material/Typography";
import TransactionStatus from "@homegame/common/dist/enum/transaction-status.enum";
import { useGame } from "./game.context";

const useStyles = makeStyles(() =>
  createStyles({
    heading: {
      fontWeight: 500,
      fontSize: "0.9rem",
      textAlign: "center",
      color: "#fff",
      borderBottom: "2px solid rgba(245,0,87,.6)",
      paddingBottom: 7,
      margin: "10px auto",
      display: "inline",
    },
    textLine: {
      display: "inline-block",
      fontWeight: 500,
      fontSize: "0.9rem",
      textAlign: "center",
      color: "#1d2658",
      width: "100%",
      textShadow: "none",
    },
  })
);

export default function PlayerGameBalance({
  user,
}: {
  user: UserInterface | undefined;
}) {
  const classes = useStyles();
  const { user: loggedInUser } = useAuth();

  const { isCashier, game } = useGame();
  const { playersGameStats } = useGameInfo(game);

  if (!user || !game || !loggedInUser) return <></>;

  const { currency } = game;

  const gamePlayersBalance = playersGameStats.find(
    (playerInfo) => playerInfo.id === user.id
  );

  if (!gamePlayersBalance) return <></>;

  const { debtToTable, amountWonLost, totalBuyIn } = gamePlayersBalance;

  const lastPlayerTransaction = getPlayerLastTransaction(
    user.id!,
    game.transactions
  );

  const finishBalanceMoney = `${Math.abs(debtToTable)} ${currency}`;
  let finishBalanceText = "";

  if (debtToTable < 0) {
    finishBalanceText = `Owned to ${
      isCashier ? "player" : "you"
    }: ${finishBalanceMoney}`;
  } else if (debtToTable > 0) {
    finishBalanceText = `${
      isCashier ? "Player owes" : "You owe"
    }: ${finishBalanceMoney}`;
  }

  const isPlayerCashedOut =
    lastPlayerTransaction?.type === TransactionType.CASH_OUT &&
    lastPlayerTransaction.status === TransactionStatus.APPROVED;

  return (
    <>
      {!isPlayerCashedOut && (
        <>
          <Typography className={classes.heading}>Buyin</Typography>
          <small className={classes.textLine}>Chips: {totalBuyIn.chips}</small>
          <small className={classes.textLine}>
            Money: {totalBuyIn.money} {game.currency}
          </small>
        </>
      )}
      {isPlayerCashedOut && amountWonLost !== 0 && (
        <>
          <small className={classes.textLine}>
            {loggedInUser.id !== user.id ? "Player" : "You"}{" "}
            {amountWonLost > 0 ? "won" : "lost"}: {Math.abs(amountWonLost)}{" "}
            {game.currency}
          </small>
          <small
            className={classes.textLine}
            style={{
              color: amountWonLost > 0 ? "#07f500" : "rgba(245,0,87,.9)",
            }}
          >
            {finishBalanceText}
          </small>
        </>
      )}
      {totalBuyIn.chips > 0 && (
        <small className={classes.textLine}>
          Total buyins: {totalBuyIn.money} {game.currency} ({totalBuyIn.chips}{" "}
          chips)
        </small>
      )}
      {isPlayerCashedOut && (
        <small className={classes.textLine}>
          Cashed out - {lastPlayerTransaction.amount} {game.currency} (
          {lastPlayerTransaction?.chips} chips)
        </small>
      )}
    </>
  );
}
