import dayjs from "dayjs";
import Grid from "@mui/material/Grid";
import makeStyles from "@mui/styles/makeStyles";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionDetails from "@mui/material/AccordionDetails";
import createStyles from "@mui/styles/createStyles";
import { UserDebtInterface } from "../core/service/user.service";
import useGameInfo from "../core/hooks/useGameInfo/use-game-info.hook";
import UsersUserOwe from "../game/users-user-owe";
import { useAuth } from "../core/context/auth.context";

const useStyles = makeStyles(() =>
  createStyles({
    heading: {
      fontSize: "0.8rem",
    },
    underButtonText: {
      padding: "0 15px",
      fontSize: 14,
      fontWeight: 500,
    },
    btn: {
      fontSize: "0.8rem",
    },
    container: {
      color: "#fff",
      background: "transparent",
      boxShadow: "none",
      "& .MuiAccordionSummary-root ": {
        padding: 0,
      },
      "& .MuiAccordionDetails-root": {
        padding: 0,
      },
      "& .MuiSvgIcon-root ": {
        color: "#fff",
      },
    },
  })
);

export interface DebtListItemComponentProps {
  debt: UserDebtInterface;
  userId?: string;
  onDebtChanged?: () => any;
}

export default function DebtListItemComponent({
  debt,
  userId,
  onDebtChanged,
}: DebtListItemComponentProps) {
  const classes = useStyles({});

  const { playersGameStats } = useGameInfo(debt.game);
  const { user: loggedInUser } = useAuth();

  const playerStats = playersGameStats.find(
    (player) => player.id === Number(userId)
  );

  if (!debt.game || !debt.table || !playersGameStats || !playerStats)
    return <></>;

  const getTotalDebtAmountString = () => {
    const currency = debt.game.currency;
    if (playerStats.totalDebt < 0) {
      if (playerStats.receivedDebt > 0) {
        return (
          <span style={{ textDecoration: "line-through" }}>
            {playerStats.totalDebt - playerStats.receivedDebt}
          </span>
        );
      }
      return playerStats.totalDebt - playerStats.receivedDebt + currency;
    } else {
      if (playerStats.payedDebt > 0) {
        return (
          <>
            <span style={{ textDecoration: "line-through" }}>
              {playerStats.totalDebt}
            </span>{" "}
            {playerStats.totalDebt - playerStats.payedDebt} {currency}
          </>
        );
      }
      return playerStats.totalDebt + currency;
    }
  };

  return (
    <Accordion className={classes.container}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        style={{}}
      >
        <Grid container spacing={1} className={classes.container}>
          <Grid item xs={12} className={classes.heading}>
            Debt in {debt.table.name} table -{" "}
            <b>{getTotalDebtAmountString()}</b>
            <br />
            <b>{dayjs(debt.game.date).format("D MMMM")}</b>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={1} className={classes.container}>
          <Grid item xs={12} p={0}>
            <UsersUserOwe
              onDebtChanged={onDebtChanged}
              game={debt.game}
              user={loggedInUser}
              isDebtsView
            />
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}
