import UserInterface from '@homegame/common/dist/interface/user.interface';
import Autocomplete from '@mui/material/Autocomplete';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import TextField from '@mui/material/TextField';
import { forwardRef, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

const useStyles = makeStyles(() =>
	createStyles({
		userAutocomplete: {
			width: '100%',
			'& .MuiInputBase-input.Mui-disabled': {
				'-webkit-text-fill-color': '#888 !important',
			},
			'& .MuiInputLabel-root': {
				color: '#fff !important',
			},
			'& .MuiAutocomplete-popper': {
				color: '#fff',
			},
		},
	})
);

const SelectUserComponent = forwardRef(
	(
		{
			disabled = false,
			list,
			label,
			onChange,
			inputPlaceholder = 'Type user name or phone and select from dropdown',
			noOptionsText = 'No Options',
			...rest
		}: {
			label?: string;
			disabled?: boolean;
			list: UserInterface[];
			onChange: (u: UserInterface) => any;
			noOptionsText?: string;
			inputPlaceholder?: string;
		},
		ref
	) => {
		const classes = useStyles();
		// @ts-ignore
		const [defaultVal, setInputValue] = useState<UserInterface>(rest.value);

		return (
			<Autocomplete
				className={classes.userAutocomplete}
				{...rest}
				ref={ref}
				onChange={(f, u) => {
					setInputValue(u as UserInterface);
					onChange(u as UserInterface);
					return u;
				}}
				value={defaultVal}
				autoComplete={false}
				options={list}
				disabled={disabled}
				getOptionLabel={(option) => (option ? `${option.name} (${option.phone})` : '')}
				isOptionEqualToValue={(option, value) => option.id === value.id}
				renderOption={(props, option, { inputValue }) => {
					const matches = match(`${option.name} (${option.phone})`, inputValue);
					const parts = parse(`${option.name} (${option.phone})`, matches);
					return (
						<li key={`_${option.id}`} {...props}>
							{parts.map((part, index) => (
								<span key={`_${index}`} style={{ fontWeight: part.highlight ? 700 : 400 }}>
									{part.text}
								</span>
							))}
						</li>
					);
				}}
				renderInput={(params) => (
					<TextField
						{...params}
						label={label}
						variant="filled"
						fullWidth
						disabled={disabled}
						placeholder="Type user name or phone and select from dropdown"
					/>
				)}
				noOptionsText={noOptionsText}
			/>
		);
	}
);

export default SelectUserComponent;
