import React from 'react';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

interface LoadingButtonInterface {
	text: string;
	isLoading?: boolean;
	form?: string;
	type?: 'submit' | 'reset' | 'button' | undefined;
	onClick?: () => any;
	variant?: 'text' | 'outlined' | 'contained';
	color?: 'inherit' | 'primary' | 'secondary' | undefined;
	size?: 'small' | 'medium' | 'large';
	disabled?: boolean;
	autoFocus?: boolean;
	style?: any;
	className?: any;
}

export default function LoadingButton({
	size = 'medium',
	text,
	isLoading = false,
	type = 'submit',
	onClick,
	form = undefined,
	variant = 'contained',
	color = 'primary',
	disabled = false,
	autoFocus = true,
	className,
	...rest
}: LoadingButtonInterface) {
	return (
		<Button
			variant={variant}
			autoFocus={autoFocus}
			type={type}
			onClick={onClick}
			disabled={isLoading || disabled}
			color={color}
			size={size}
			form={form}
			className={className}
			{...rest}
		>
			{isLoading && (
				<>
					<CircularProgress size={14} style={{ marginRight: 5 }} />
					{text}
				</>
			)}
			{!isLoading && text}
		</Button>
	);
}
