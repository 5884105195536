import dayjs from 'dayjs';
import { useMemo } from 'react';
import GameInterface from '@homegame/common/dist/interface/game.interface';
import TransactionType from '@homegame/common/dist/enum/transaction-type.enum';
import GameInfoInterface from './interface/game-info.interface';
import computePlayerMetrics from './get-player-game-stats';
import TransactionStatus from '@homegame/common/dist/enum/transaction-status.enum';
import GamePlayerStatus from '@homegame/common/dist/enum/game-player-status.enum';
import getPlayerTransactions from './get-player-transactions';

const calculatePlayerTotal = (playerMap: Record<number, number>, playerId?: number) => (playerId ? Math.abs(playerMap[playerId] || 0) : 0);

/**
 * The `useGameInfo` function calculates various statistics and information about a game, such as total
 * buy-ins, cash-outs, debts, player balances, and duration.
 * @param {GameInterface} [game] - The `game` parameter is an optional object that represents a game.
 * It has the following properties:
 * @returns a memoized object of type GameInfoInterface.
 */
export default function useGameInfo(game?: GameInterface): GameInfoInterface {
	return useMemo(() => {
		if (!game || !game.players || !game.transactions) {
			return {
				totalBuyInChips: 0,
				totalBuyInMoney: 0,
				totalBuyInDebt: 0,
				currentChipsVal: 0,
				currentMoneyVal: 0,
				totalCashOutDebt: 0,
				totalCashOutChips: 0,
				totalCashOutMoney: 0,
				bestCashOutChips: undefined,
				bestBuyInChips: undefined,
				playersGameStats: [],
				usersWithDebts: [],
				totalGameDebt: 0,
				duration: '00:00:00',
				gameRate: 1,
			};
		}

		// Calculate the game rate
		const gameRate = game.moneyValue / game.chipValue;

		let totalBuyInChips = 0;
		let totalBuyInMoney = 0;
		let totalCashOutChips = 0;
		let totalCashOutMoney = 0;

		const playerBuyIns: Record<number, number> = {};
		const playerCashOuts: Record<number, number> = {};

		for (const { type, fromUserId, toUserId, chips, amount, status } of game.transactions) {
			if (status !== TransactionStatus.APPROVED) continue;
			if (type === TransactionType.BUY_IN) {
				totalBuyInChips += chips;
				totalBuyInMoney += amount;
				playerBuyIns[fromUserId] = (playerBuyIns[fromUserId] || 0) + chips;
			} else if (type === TransactionType.CASH_OUT) {
				totalCashOutChips += chips;
				totalCashOutMoney += amount;
				playerCashOuts[toUserId] = (playerCashOuts[toUserId] || 0) + chips;
			}
		}

		const gamePlayers = game.players
			.filter((gamePlayer) => gamePlayer.status === GamePlayerStatus.REGISTERED)
			.filter((gamePlayer) => getPlayerTransactions(gamePlayer.playerId!, game.transactions).length > 0);

		const bestBuyInChips = gamePlayers
			.map((p) => ({
				id: p.playerId || -1,
				name: p.player?.name || '',
				total: calculatePlayerTotal(playerBuyIns, p.playerId),
			}))
			.sort((a, b) => b.total - a.total)[0];

		const bestCashOutChips = gamePlayers
			.map((p) => ({
				id: p.playerId || -1,
				name: p.player?.name || '',
				total: calculatePlayerTotal(playerCashOuts, p.playerId),
			}))
			.sort((a, b) => b.total - a.total)[0];

		// Calculate the players' balances and debts
		const playersGameStats = gamePlayers
			.map((player) => computePlayerMetrics(player, game.transactions || [], gameRate))
			.sort((a, b) => a.totalBuyIn.chips - a.totalCashout.chips - (b.totalBuyIn.chips - b.totalCashout.chips));

		const totalBuyInDebt = totalBuyInChips * gameRate - totalBuyInMoney;
		const totalCashOutDebt = totalCashOutMoney > 0 ? totalBuyInDebt : 0;
		const usersWithDebts = playersGameStats.filter((u) => u.debtToTable > 0);
		const totalGameDebt = usersWithDebts.reduce((acc, u) => acc + u.debtToTable, 0);
		const durationNumber = dayjs(game.finishedAt || dayjs()).diff(dayjs(game.startedAt), 'ms');
		const duration = dayjs.duration(durationNumber).format('HH:mm:ss');
		return {
			totalBuyInChips,
			totalBuyInMoney,
			totalBuyInDebt,
			totalCashOutChips,
			totalCashOutMoney,
			bestCashOutChips,
			bestBuyInChips,
			playersGameStats: playersGameStats,
			usersWithDebts,
			totalCashOutDebt,
			totalGameDebt,
			duration,
			currentChipsVal: totalBuyInChips - Math.abs(totalCashOutChips),
			currentMoneyVal: Math.abs(totalBuyInMoney) - totalCashOutMoney,
			playerWithBiggestWonMoney: playersGameStats[0],
			playerWithBiggestLossMoney: playersGameStats[playersGameStats.length - 1],
			gameRate,
		};
	}, [game]);
}
