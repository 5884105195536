import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import GameDuration from "./game-duration";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import UserInterface from "@homegame/common/dist/interface/user.interface";
import PlayerGameBalance from "./player-game-balance";
import UsersUserOwe from "./users-user-owe";
import useGameInfo from "../core/hooks/useGameInfo/use-game-info.hook";
import PlayerStatusComponent from "./player-status.component";
import GamePlayerStatus from "@homegame/common/dist/enum/game-player-status.enum";
import InviteControls from "./player-invite-controls.component";
import PlayerDeleteBtn from "./player-delete-btn.component";
import PlayerTransactionsBtns from "./player-transactions-btns.component";
import PlayerTransactions from "./player-transactions.component";
import { useGame } from "./game.context";

const useStyles = makeStyles(() =>
  createStyles({
    heading: {
      fontWeight: 500,
      fontSize: "1.1rem",
      paddingLeft: 10,
      paddingRight: 10,
      textAlign: "center",
      color: "#fff",
      position: "relative",
      borderBottom: "2px solid rgba(245,0,87,.6)",
      paddingBottom: 0,
      marginBottom: 0,
    },
    closeBtn: {
      position: "absolute",
      top: 10,
      right: 10,
    },
    playerName: {
      position: "relative",
      fontWeight: 500,
      fontSize: "1.2rem",
      "& .MuiChip-root": {
        right: "auto",
      },
    },
  })
);

const DIVIDER_MARGIN = 0;

/**
 * Renders the game information for a specific player.
 *
 * @param game - The game object.
 * @param user - The user object.
 * @param isDialog - Optional. Indicates whether the component is rendered as a dialog. Default is false.
 * @param onTransactionSent - Optional. Callback function triggered when a transaction is sent.
 * @param onCloseBtnClick - Optional. Callback function triggered when the close button is clicked.
 * @param onPlayerDelete - Optional. Callback function triggered when a player is deleted.
 * @param onPlayerStatusChange
 * @returns The rendered component.
 */
export default function GameInfoPlayer({
  user,
  isDialog = false,
  onTransactionSent,
  onCloseBtnClick,
  onPlayerDelete,
  onPlayerStatusChange,
}: {
  user: UserInterface | undefined;
  isDialog?: boolean;
  onPlayerDelete?: () => void;
  onPlayerStatusChange?: () => void;
  onTransactionSent?: () => void;
  onCloseBtnClick?: () => void;
}) {
  const { isCashier, isNew, isOngoing, isCanceled, game } = useGame();

  const classes = useStyles();

  const { duration } = useGameInfo(game);

  if (!game || !user || isCanceled) return <></>;

  const currentGamePlayer = game.players?.find((p) => p.playerId === user.id);

  return (
    <>
      <Grid container spacing={1}>
        {isDialog && (
          <>
            <Grid item xs={12}>
              <Typography align="center" className={classes.playerName}>
                {user.name}
                {currentGamePlayer && (
                  <PlayerStatusComponent
                    table={game.table}
                    gamePlayer={currentGamePlayer}
                  />
                )}
              </Typography>
              {isDialog && (
                <IconButton
                  className={classes.closeBtn}
                  onClick={onCloseBtnClick}
                >
                  <CloseIcon />
                </IconButton>
              )}
            </Grid>
            <Grid item xs={12}>
              <Divider sx={{ mt: DIVIDER_MARGIN, mb: DIVIDER_MARGIN }} />
            </Grid>
          </>
        )}

        {currentGamePlayer?.status === GamePlayerStatus.REGISTERED && (
          <>
            {!isDialog && (
              <Grid item xs={12} pt={0}>
                <GameDuration game={game} />
              </Grid>
            )}
            <Grid
              item
              xs={12}
              display="flex"
              flexDirection="column"
              justifyContent="center"
            >
              {!isDialog && !isNew && !isOngoing && (
                <Typography
                  className={classes.heading}
                  style={{ borderBottom: "none" }}
                  variant="h5"
                  gutterBottom
                >
                  <small>Game duration - {duration}</small>
                </Typography>
              )}

              <PlayerGameBalance user={user} />

              <UsersUserOwe game={game} user={user} />

              <PlayerTransactions
                user={user}
                onTransactionSent={onTransactionSent}
              />
            </Grid>
          </>
        )}

        {currentGamePlayer && (
          <InviteControls
            currentGamePlayer={currentGamePlayer}
            user={user}
            onPlayerStatusChange={onPlayerStatusChange}
          />
        )}

        {(isOngoing || isNew) && isCashier && (
          <>
            <Grid item xs={12}>
              <Divider sx={{ mt: DIVIDER_MARGIN, mb: DIVIDER_MARGIN }} />
            </Grid>
            <Grid
              item
              xs={12}
              textAlign="center"
              justifyContent="space-around"
              display="flex"
              p={1}
            >
              <PlayerTransactionsBtns
                user={user}
                onTransactionSent={onTransactionSent}
              />
              <PlayerDeleteBtn user={user} onPlayerDelete={onPlayerDelete} />
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
}
