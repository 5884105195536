import { ThemeProvider, Theme, StyledEngineProvider, createTheme } from '@mui/material/styles';

declare module '@mui/styles/defaultTheme' {
	// eslint-disable-next-line @typescript-eslint/no-empty-interface
	interface DefaultTheme extends Theme {}
}

const textShadowBase = '1px 1px 1px rgba(0,0,0,.6)';

const theme = createTheme({
	palette: {
		primary: {
			main: '#333b66',
			contrastText: '#fff',
		},
		secondary: {
			main: 'rgba(245,0,87,.6)',
		},
	},
	typography: {
		fontFamily: '"Roboto", sans-serif',
		fontSize: 16,
		fontWeightBold: 600,
		fontWeightRegular: 400,
		fontWeightMedium: 500,
		h1: {
			color: '#fff',
			textShadow: textShadowBase,
		},
		h6: {
			color: '#fff',
			textShadow: textShadowBase,
		},
		h4: {
			color: '#fff',
			textShadow: textShadowBase,
		},
		h5: {
			color: '#fff',
			textShadow: textShadowBase,
		},
		h2: {
			color: '#fff',
			fontWeight: 300,
			textAlign: 'center',
			marginTop: 15,
			textShadow: textShadowBase,
		},
		subtitle1: {
			fontSize: 20,
			lineHeight: 1.6,
			fontWeight: 500,
			letterSpacing: 0.4,
			textTransform: 'capitalize',
			color: '#fff',
			textShadow: textShadowBase,
		},
		body2: {
			color: '#fff',
			textShadow: textShadowBase,
		},
		body1: {
			color: '#fff',
			textShadow: textShadowBase,
		},
		caption: {
			fontSize: 15,
			lineHeight: 1.65,
			fontWeight: 300,
			letterSpacing: 0.4,
			color: '#fff',
			fontFamily: '"Roboto", sans-serif',
			textShadow: textShadowBase,
		},
		button: {
			color: '#fff',
			textShadow: textShadowBase,
			fontSize: '0.7rem',
		},
	},
	components: {
		MuiCssBaseline: {
			styleOverrides: {
				body: {
					background: 'rgb(102,112,167)',
				},
			},
		},
		MuiDialog: {
			styleOverrides: {
				root: {
					'& .MuiDialog-paper  ': {
						width: '90%',
						maxWidth: '500px',
						margin: '15px',
					},
				},
			},
		},
		MuiDialogContentText: {
			styleOverrides: {
				root: {
					color: '#000',
				},
			},
		},
		MuiIconButton: {
			styleOverrides: {
				root: {
					// color: 'rgba(245,0,87,1)',
					color: '#fff',
					filter: 'drop-shadow( 1px 1px black)',
				},
			},
		},
		MuiButton: {
			styleOverrides: {
				root: {
					borderRadius: 20,
				},
			},
		},
		MuiTextField: {
			styleOverrides: {
				root: {
					marginBottom: 10,
					'& label, & input, .MuiFormLabel-root.Mui-focused': {
						color: '#fff',
					},
					'& .MuiFilledInput-underline:after': {
						borderColor: '#fff',
					},
				},
			},
		},
		MuiPaper: {
			styleOverrides: {
				root: {
					backgroundColor: '#333b66',
					// backgroundColor: 'rgb(102,112,167)',
				},
			},
		},
		MuiListItemText: {
			styleOverrides: {
				secondary: {
					color: '#fff',
				},
			},
		},
		MuiDivider: {
			styleOverrides: {
				root: {
					borderColor: 'rgba(245,0,87,.6)',
					borderBottomWidth: 2,
				},
			},
		},
		MuiSelect: {
			styleOverrides: {
				icon: {
					color: '#fff',
				},
				select: {
					color: '#fff',
				},
			},
		},
		MuiInput: {
			styleOverrides: {
				inputAdornedEnd: { fontSize: '0.9rem' },
				inputAdornedStart: { fontSize: '0.9rem' },
				root: {
					fontSize: '0.9rem',
					'&:before': {
						borderBottom: '2px solid rgba(255,255,255,.4)',
					},
					'&:after': {
						borderBottom: '2px solid #fff',
					},
				},
			},
		},
		MuiFilledInput: {
			styleOverrides: {
				root: {
					fontSize: '0.9rem',
					'&:before': {
						borderBottom: '2px solid rgba(255,255,255,.4)',
					},
				},
			},
		},
		MuiInputAdornment: {
			styleOverrides: {
				root: {
					fontSize: '0.9rem',
					'& .MuiTypography-root ': {
						fontSize: '0.9rem',
					},
				},
			},
		},
		MuiTabs: {
			styleOverrides: {
				indicator: {
					backgroundColor: '#b78fff',
				},
			},
		},
		MuiTab: {
			styleOverrides: {
				root: {
					color: '#fff !important',
					opacity: 0.7,
					'&.Mui-selected': {
						color: '#fff !important',
						opacity: '1 !important',
					},
				},
			},
		},
		MuiAutocomplete: {
			styleOverrides: {
				noOptions: {
					color: '#fff',
					fontSize: 16,
					textAlign: 'center',
				},
			},
		},
		MuiSwitch: {
			styleOverrides: {
				root: {
					padding: 5,
					'& .Mui-disabled': {
						opacity: 0.12,
					},
					'& .MuiSwitch-thumb:after': { content: '""' },
					'& .MuiSwitch-thumb:before': {
						content: '""',
						display: 'block',
						background: 'linear-gradient(to bottom, #fff 0%, #eee 100%)',
						borderRadius: '50%',
						boxShadow: '0 0 0 1px rgba(0, 0, 0, 0.05)',
						width: 20,
						height: 20,
						position: 'absolute',
						top: 9,
						left: 9,
						transition: 'left 0.25s',
						'@media (prefers-color-scheme: dark)': {
							background: 'linear-gradient(to bottom, #fff 0%, #eee 100%) !important',
							boxShadow: '0 0 0 1px rgba(0, 0, 0, 0.05) !important',
						},
					},
					'& .MuiSwitch-thumb': {
						background: '#ccc !important',
						borderRadius: 16,
						boxShadow: 'inset 0px 0px 10px 1px rgba(0,0,0,0.3), 0px 1px 6px 1px rgba(0,0,0,.5)',
						'@media (prefers-color-scheme: dark)': {
							background: '#ccc !important !important',
							boxShadow: 'inset 0px 0px 10px 1px rgba(0,0,0,0.3), 0px 1px 6px 1px rgba(0,0,0,.5) !important',
						},
					},
					'& .MuiButtonBase-root': {
						color: 'white',
					},
					'& .MuiSwitch-track ': {
						colorScheme: 'dark',
						opacity: '1',
						borderRadius: 15,
						background: 'linear-gradient(to bottom, #ccc 0%, #eee 100%)',
						boxShadow: '0 0 0 1px rgba(0, 0, 0, 0.05), inset 0px 2px 5px 0px rgba(0,0,0,0.7)',
						'@media (prefers-color-scheme: dark)': {
							background: 'linear-gradient(to bottom, #ccc 0%, #eee 100%) !important',
							boxShadow: '0 0 0 1px rgba(0, 0, 0, 0.05), inset 0px 2px 5px 0px rgba(0,0,0,0.7) !important',
						},
					},
					'& .Mui-checked ': {
						'& + .MuiSwitch-track': {
							background: 'rgba(245,0,87,.6) !important',
						},
					},
				},
			},
		},
	},
});

export default function HGTheme({ children }: { children: any }) {
	return (
		<StyledEngineProvider injectFirst>
			<ThemeProvider theme={theme}>{children}</ThemeProvider>
		</StyledEngineProvider>
	);
}
