import { Button, Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { useAuth } from "../core/context/auth.context";
import TransactionType from "@homegame/common/dist/enum/transaction-type.enum";
import Stack from "@mui/material/Stack";
import ConfirmDialog from "../core/components/confirmation-dialog.component";
import CreateGameDialog from "./CreateGame/create-game.component";
import GameService from "../core/service/game.service";
import useGameInfo from "../core/hooks/useGameInfo/use-game-info.hook";
import isAllCashOutsDone from "../core/hooks/useGameInfo/is-all-cashouts-done";
import GameDuration from "./game-duration";
import EditIcon from "@mui/icons-material/Edit";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import DoDisturbIcon from "@mui/icons-material/DoDisturb";
import Divider from "@mui/material/Divider";
import { useToast } from "../core/hooks/use-toast.hook";
import { useGame } from "./game.context";

const useStyles = makeStyles(() =>
  createStyles({
    listWrap: {
      width: "100%",
      display: "flex",
      flexWrap: "wrap",
      alignContent: "space-between",
      flexDirection: "column",
      justifyContent: "center",
      "@media (orientation: landscape)": { flexDirection: "row" },
    },
    listItem: {
      padding: 0,
      width: "100%",
      fontSize: 11,
      textAlign: "center",
      // '@media (orientation: landscape)': { width: '50%', padding: '0px 30px' },
      "& .MuiListItemText-root .MuiTypography-root": {
        color: "#1d2658",
        fontWeight: 500,
        fontSize: 13,
        textShadow: "none",
      },
      "& >  .MuiTypography-body1.MuiTypography-root": {
        color: "#1d2658",
        fontWeight: 500,
        fontSize: 13,
        textShadow: "none",
      },
    },
    heading: {
      fontWeight: 500,
      fontSize: "0.9rem",
      textAlign: "center",
      color: "#fff",
      borderBottom: "2px solid rgba(245,0,87,.6)",
      paddingBottom: 7,
      margin: "10px auto",
      display: "inline",
    },
    val: {
      fontWeight: 500,
      fontSize: "0.9rem",
      textAlign: "center",
      color: "#1d2658",
      textShadow: "none",
    },
    btnsWrap: {
      display: "flex",
      justifyContent: "center",
      marginTop: 10,
      flexWrap: "wrap",
      flexDirection: "row",
      "& button": {
        margin: "16px 5px !important",
        width: "45%",
        "@media (orientation: landscape)": { width: "auto" },
      },
    },
  })
);

export default function GameInfoCashier() {
  const classes = useStyles();
  const gameService = new GameService();
  const { showToast } = useToast();
  const { user } = useAuth();
  const {
    game,
    setPlayersWithErrors,
    isNew,
    isOngoing,
    isFinished,
    reloadGame,
  } = useGame();

  const {
    currentChipsVal,
    totalBuyInChips,
    totalCashOutChips,
    duration,
    currentMoneyVal,
    bestBuyInChips,
    bestCashOutChips,
    playerWithBiggestWonMoney,
    totalCashOutMoney,
    totalBuyInMoney,
    playerWithBiggestLossMoney,
  } = useGameInfo(game);

  const [dialogState, setDialogState] = useState<{
    open: boolean;
    type: "start" | "cancel" | "finish";
    isLoading: boolean;
  }>({
    open: false,
    type: "start",
    isLoading: false,
  });
  const [editGameOpened, setEditGameOpened] = useState<boolean>(false);

  if (!game) return <></>;

  const handleDialogClose = () =>
    setDialogState({ open: false, type: "start", isLoading: false });

  const handleDialogOpen = (type: "start" | "cancel" | "finish") => {
    setDialogState({ open: true, type, isLoading: false });
  };

  const handleOnFinishGameClick = () => {
    if (!game) return;

    if (!isAllCashOutsDone(game)) {
      showToast(
        "All players must do cash out before finishing game.",
        "warning"
      );
      return;
    }

    if (currentChipsVal > 0) {
      showToast(`${currentChipsVal} chips are still not returned`, "warning");
      return;
    }

    handleDialogOpen("finish");
  };

  const toggleGame = () => setEditGameOpened(!editGameOpened);

  const onGameClose = async () => {
    setEditGameOpened(false);
    await reloadGame();
  };

  const doDialogAction = async () => {
    setDialogState({ ...dialogState, isLoading: true });
    try {
      if (dialogState.type === "start") {
        await gameService.startGame(game.id!);
        showToast("Game started!", "info");
      } else if (dialogState.type === "cancel") {
        await gameService.cancelGame(game.id!);
        showToast("Game canceled!", "info");
      } else if (dialogState.type === "finish") {
        await gameService.finishGame(game.id!);
        showToast("Game finished!", "info");
      }
      await reloadGame();
    } catch (e) {
      const { message } = e as Error;
      if (dialogState.type === "start") {
        const playerIds = game?.players?.map((p) => Number(p.playerId)) || [];
        const buyInsIds =
          game?.transactions
            ?.filter((t) => t.type === TransactionType.BUY_IN)
            .map((t) => t.toUserId) || [];
        const playersWithoutBuyIns = playerIds.filter(
          (pId) => !buyInsIds.includes(Number(pId))
        );

        if (playersWithoutBuyIns.length)
          setPlayersWithErrors(playersWithoutBuyIns);
      }
      showToast(message, "error");
    } finally {
      setDialogState({ ...dialogState, isLoading: false, open: false });
    }
  };

  return (
    <>
      <Grid container spacing={3} justifyContent="center">
        <Grid item xs={12} sm={12} alignSelf="center">
          <GameDuration />
        </Grid>

        <Grid item sm={4} xs={6} flexDirection="column" display="flex">
          <Typography className={classes.heading}>Buyin</Typography>
          <Typography className={classes.val}>
            {totalBuyInChips} chips
          </Typography>
          <Typography className={classes.val} marginBottom={2}>
            {totalBuyInMoney} {game.currency}
          </Typography>

          {isOngoing && (
            <>
              <Typography className={classes.listItem}>
                Chips in game
                <br />
                <Typography component="span">
                  {currentChipsVal} chips
                </Typography>
              </Typography>

              <Typography className={classes.listItem}>
                Money in game <br />
                <Typography component="span">
                  {currentMoneyVal} {game.currency}
                </Typography>
              </Typography>
            </>
          )}
          {!isNew && (
            <>
              <Typography className={classes.listItem}>
                Biggest total buy in
                <br />
                <Typography component="span">
                  {bestBuyInChips ? bestBuyInChips.total : 0} chips (
                  {bestBuyInChips ? `by ${bestBuyInChips.name}` : ""})
                </Typography>
              </Typography>
            </>
          )}
          {isFinished && (
            <>
              <Typography className={classes.listItem}>
                Game duration
                <br />
                <Typography component="span">{duration}</Typography>
              </Typography>
            </>
          )}
        </Grid>

        {!isNew && (
          <Grid item sm={4} xs={6} flexDirection="column" display="flex">
            <Typography className={classes.heading}>Cashout</Typography>
            <Typography className={classes.val}>
              {totalCashOutChips} chips
            </Typography>
            <Typography className={classes.val} marginBottom={2}>
              {totalCashOutMoney} {game.currency}
            </Typography>

            {!isNew && (
              <>
                <Typography className={classes.listItem}>
                  Biggest total cash out <br />
                  <Typography component="span">
                    {bestCashOutChips
                      ? `${bestCashOutChips.total} chips (by ${bestCashOutChips.name})`
                      : "None"}
                  </Typography>
                </Typography>
              </>
            )}
            {isFinished && (
              <>
                {playerWithBiggestWonMoney && (
                  <Typography className={classes.listItem}>
                    Biggest win <br />
                    <Typography component="span">
                      {Math.abs(
                        playerWithBiggestWonMoney.totalBuyIn.chips -
                          playerWithBiggestWonMoney.totalCashout.chips
                      )}{" "}
                      {game.currency} by {playerWithBiggestWonMoney.name}
                    </Typography>
                  </Typography>
                )}

                {playerWithBiggestLossMoney && (
                  <Typography className={classes.listItem}>
                    Biggest loss <br />
                    <Typography component="span">
                      {playerWithBiggestLossMoney.totalBuyIn.chips -
                        playerWithBiggestLossMoney.totalCashout.chips}{" "}
                      {game.currency} by {playerWithBiggestLossMoney.name}
                    </Typography>
                  </Typography>
                )}
              </>
            )}
          </Grid>
        )}

        <Grid item xs={12} sm={12} alignSelf="center">
          {(isNew || isOngoing) && (
            <Stack spacing={2} className={classes.btnsWrap}>
              {isOngoing && (
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleOnFinishGameClick}
                >
                  Finish game
                </Button>
              )}

              {isNew && (
                <>
                  <Grid item xs={12} p={1}>
                    <Divider sx={{ mt: 0, mb: 0 }} />
                  </Grid>
                  <Button
                    onClick={() => handleDialogOpen("start")}
                    color="success"
                    variant="contained"
                    startIcon={<PlayArrowIcon />}
                  >
                    Start game
                  </Button>
                  <Button
                    onClick={toggleGame}
                    variant="contained"
                    color="warning"
                    startIcon={<EditIcon />}
                  >
                    Edit game
                  </Button>
                  <Button
                    onClick={() => handleDialogOpen("cancel")}
                    color="error"
                    variant="contained"
                    startIcon={<DoDisturbIcon />}
                  >
                    Cancel game
                  </Button>
                </>
              )}
            </Stack>
          )}
        </Grid>
      </Grid>

      <ConfirmDialog
        text={`Are you sure you want to ${dialogState.type} game?`}
        isLoading={dialogState.isLoading}
        actionText={`${dialogState.type} game`}
        open={dialogState.open}
        onClose={handleDialogClose}
        action={doDialogAction}
      />

      {editGameOpened && (
        <CreateGameDialog
          initialValues={game}
          table={game.table}
          userId={Number(user?.id)}
          onClose={onGameClose}
        />
      )}
    </>
  );
}
