import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import AccordionDetails from "@mui/material/AccordionDetails";
import TransactionsListComponent from "../transactions/transactions-list.component";
import Accordion from "@mui/material/Accordion";
import { SyntheticEvent, useState } from "react";
import UserInterface from "@homegame/common/dist/interface/user.interface";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { useGame } from "./game.context";

interface PlayerTransactionsProps {
  user: UserInterface | undefined;
  onTransactionSent?: () => void;
}

const useStyles = makeStyles(() =>
  createStyles({
    transactionsRoot: {
      color: "#1d2658",
      boxShadow: "none",
      backgroundColor: "transparent",
      "& .MuiAccordionSummary-content": {
        display: "contents",
      },
      "& .MuiAccordionSummary-content .MuiTypography-root ": {
        color: "#1d2658",
        textShadow: "none",
        fontWeight: 500,
        fontSize: "0.9rem",
      },
      "& .MuiAccordionSummary-expandIconWrapper  ": {
        color: "#1d2658",
        textShadow: "none",
        fontWeight: 500,
        fontSize: "0.9rem",
      },
      "& .MuiButtonBase-root ": {
        padding: 0,
      },
      "& .MuiAccordionDetails-root ": {
        padding: 0,
      },
    },
  })
);

export default function PlayerTransactions({
  user,
  onTransactionSent,
}: PlayerTransactionsProps) {
  const classes = useStyles();

  const { game, isCashier } = useGame();

  const [transactionsExpanded, setTransactionsExpanded] = useState<
    string | false
  >("transactions1");

  const handleTransactionsAccordionChange =
    (panel: string) => (_: SyntheticEvent, isExpanded: boolean) => {
      setTransactionsExpanded(isExpanded ? panel : false);
    };

  return (
    <>
      <Accordion
        className={classes.transactionsRoot}
        expanded={transactionsExpanded === "transactions1"}
        onChange={handleTransactionsAccordionChange("transactions1")}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>{isCashier ? "Player" : "Your"} Transactions</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TransactionsListComponent
            showStatus
            pageSize={4}
            game={game}
            userId={user?.id}
            onActionDone={onTransactionSent}
          />
        </AccordionDetails>
      </Accordion>
    </>
  );
}
