import { axios } from "../api";
import { API_URL } from "../config";
import { AxiosRequestConfig } from "axios";
import { toast } from "react-toastify";

export interface BaseServiceInterface<I> {
  baseEndpoint: string;
  list: (params: any) => Promise<[I[], number]>;
}

export default class BaseService {
  baseEndpoint = "";

  baseTransformResponse(data: any) {
    let resp;

    try {
      resp = JSON.parse(data);
    } catch (error) {
      throw Error(
        `[requestClient] Error parsing response JSON data - ${JSON.stringify(
          error
        )}`
      );
    }

    if (resp.status === "success") {
      if (resp.total) {
        return [resp.data, resp.total];
      }
      return resp.data;
    }
    return resp;
  }

  async request<T>(opts: AxiosRequestConfig): Promise<T> {
    try {
      return await axios.request({ baseURL: API_URL, ...opts });
    } catch (e) {
      console.error(e);
      throw BaseService.handleError(e);
    }
  }

  static handleError(e: any) {
    let errorMsg = e.message;

    if (e.response?.data?.message) {
      errorMsg = e.response.data.message;
    }

    if (e.response?.data?.data && e.response.data.data.length) {
      e.response.data.data.forEach(
        ({ msg, param }: { msg: string; param: string }) => {
          errorMsg += `. ${msg}. (${param})`;
        }
      );
    }

    e.message = errorMsg;
    toast.error(errorMsg, { toastId: errorMsg });

    throw e;
  }

  async baseList<T>(params?: any): Promise<[T[], number]> {
    return this.request<[T[], number]>({
      method: "get",
      url: this.baseEndpoint,
      params,
      transformResponse: [this.baseTransformResponse],
    });
  }

  async baseSingle<T>(
    id: string | number | undefined,
    params?: any
  ): Promise<T> {
    if (typeof id === "undefined") throw new Error("no id");

    return this.request<T>({
      method: "get",
      url: `${this.baseEndpoint}/${id}`,
      params,
      transformResponse: [this.baseTransformResponse],
    });
  }

  async baseDelete<T>(
    id: string | number | undefined,
    params?: any
  ): Promise<any> {
    if (typeof id === "undefined") throw new Error("no id");
    return this.request<T>({
      method: "delete",
      url: `${this.baseEndpoint}/${id}`,
      params,
    });
  }

  async baseSave<T>(
    id: string | number | undefined,
    data: Partial<T>
  ): Promise<T> {
    if (typeof id === "undefined") throw new Error("no id");
    return this.request<T>({
      method: "put",
      url: `${this.baseEndpoint}/${id}`,
      data,
      transformResponse: [this.baseTransformResponse],
    });
  }

  async baseCreate<T>(data: Partial<T>): Promise<T> {
    return this.request<T>({
      method: "post",
      url: `${this.baseEndpoint}`,
      data,
      transformResponse: [this.baseTransformResponse],
    });
  }
}
