import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import GameInterface from "@homegame/common/src/interface/game.interface";
import BaseService from "./base.service";
import { dateWithoutTimezone } from "../helpers/date-without-ts.helper";

dayjs.extend(utc);
dayjs.extend(timezone);

export default class GameService extends BaseService {
  baseEndpoint = "games";

  async list(params?: any): Promise<[GameInterface[], number]> {
    const [data, total] = await this.baseList<GameInterface>({ ...params });
    return [data, total];
  }

  async single(id: string | number | undefined): Promise<GameInterface> {
    return this.baseSingle<GameInterface>(id);
  }

  async save(
    data: Partial<GameInterface>,
    id?: number | string | undefined | null
  ): Promise<GameInterface> {
    data.maximumBuyIn = data.maximumBuyIn ? Number(data.maximumBuyIn) : null;
    data.minimumBuyIn = Number(data.minimumBuyIn);
    data.moneyValue = Number(data.moneyValue);
    data.chipValue = Number(data.chipValue);
    data.playersLimit = Number(data.playersLimit);
    data.minimumMngApproveTime = Number(data.minimumMngApproveTime);
    // @ts-ignore
    data.date = dateWithoutTimezone(data.date || new Date());

    if (id) {
      try {
        return this.baseSave(id, data);
      } catch (e) {
        throw BaseService.handleError(e);
      }
    }
    return this.baseCreate(data);
  }

  async startGame(gameId: number | string): Promise<void> {
    return this.request({
      method: "post",
      url: `${this.baseEndpoint}/${gameId}/start`,
    });
  }

  async cancelGame(gameId: number | string): Promise<void> {
    return this.request({
      method: "post",
      url: `${this.baseEndpoint}/${gameId}/cancel`,
    });
  }

  async finishGame(gameId: number | string): Promise<void> {
    return this.request({
      method: "post",
      url: `${this.baseEndpoint}/${gameId}/finish`,
    });
  }

  /**
   *
   * @param gameId
   * @param gamePlayerId
   * @deprecated
   */
  async addPlayer(
    gameId: string | number | undefined,
    gamePlayerId: string | number | undefined
  ): Promise<void> {
    return this.request({
      method: "post",
      url: `${this.baseEndpoint}/${gameId}/add-player`,
      data: { gamePlayerId },
    });
  }

  async invitePlayer(
    gameId: string | number | undefined,
    gamePlayerId: string | number | undefined
  ): Promise<void> {
    return this.request({
      method: "post",
      url: `${this.baseEndpoint}/${gameId}/invite-player`,
      data: { gamePlayerId },
    });
  }

  async acceptInvite(
    gameId: string | number | undefined,
    gamePlayerId: string | number | undefined
  ): Promise<void> {
    return this.request({
      method: "post",
      url: `${this.baseEndpoint}/${gameId}/accept-invite`,
      data: { gamePlayerId },
    });
  }

  async rejectInvite(
    gameId: string | number | undefined,
    gamePlayerId: string | number | undefined
  ): Promise<void> {
    return this.request({
      method: "post",
      url: `${this.baseEndpoint}/${gameId}/reject-invite`,
      data: { gamePlayerId },
    });
  }

  async deletePlayer(
    gameId: string | number | undefined,
    gamePlayerId: string | number | undefined
  ): Promise<GameInterface> {
    return this.request({
      method: "delete",
      url: `${this.baseEndpoint}/${gameId}/players/${gamePlayerId}`,
    });
  }
}
