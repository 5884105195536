import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import DebtListItemComponent from "../debt-list-item.component";
import MoneyOffIcon from "@mui/icons-material/MoneyOff";
import { useQuery as useQueryLoader } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import UserService, {
  UserDebtInterface,
} from "../../core/service/user.service";
import { useAuth } from "../../core/context/auth.context";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { useSocket } from "../../core/context/socket.context";
import { usePullToRefresh } from "../../core/context/pull-to-refresh.context";

const useStyles = makeStyles(() =>
  createStyles({
    listItem: {
      paddingTop: 10,
      paddingLeft: 10,
      paddingRight: 10,
      paddingBottom: 5,
      marginBottom: 5,
      backgroundColor: "#47528c",
      width: "100%",
      height: "100%",
      display: "flex",
      flexDirection: "column",
      borderRadius: 5,
      "& small": {
        color: "#fff",
        fontWeight: 500,
        fontSize: 13,
        paddingTop: 5,
      },
    },
  })
);

export default function DebtsTab() {
  const classes = useStyles({});
  const { transactionSocket } = useSocket();
  const { setRefreshFunction } = usePullToRefresh();

  const { user } = useAuth();

  const userService = new UserService();
  const [debts, setDebts] = useState<UserDebtInterface[]>([]);

  const userId = Number(user?.id);

  const {
    data,
    isFetching: isDebtsLoading,
    isError: isDebtsError,
    error,
    refetch,
  } = useQueryLoader({
    queryKey: [`debts_list`, { userId }],
    queryFn: () => userService.getDebts(userId),
    initialData: [],
  });

  useEffect(() => {
    if (data) setDebts(data);
  }, [data]);

  useEffect(() => {
    const refreshData = async () => {
      await refetch();
    };
    setRefreshFunction(refreshData);
    return () => {
      setRefreshFunction();
    };
  }, [setRefreshFunction]);

  useEffect(() => {
    const events = ["transactionUpdated", "transactionCreated"];
    if (transactionSocket) {
      events.forEach((eventName) => {
        transactionSocket.on(eventName, (data) => {
          if (
            [data.transaction.fromUserId, data.transaction.toUserId].includes(
              user?.id
            )
          ) {
            refetch();
          }
        });
      });

      return () => {
        events.forEach((eventName) => {
          transactionSocket.off(eventName);
        });
      };
    }
  }, [transactionSocket]);

  const onTransactionStatusChanged = () => {
    refetch();
  };

  return (
    <Grid item xs={12}>
      <Grid container>
        {isDebtsError && <Typography>{error.message}</Typography>}
        {!isDebtsError &&
          debts.map((debt, i) => (
            <Grid item xs={12} className={classes.listItem} key={i}>
              <DebtListItemComponent
                debt={debt}
                userId={String(user?.id)}
                onDebtChanged={onTransactionStatusChanged}
              />
            </Grid>
          ))}
        {!isDebtsLoading && !isDebtsError && debts.length === 0 && (
          <Grid item xs={12} textAlign="center" p={5} fontSize={14}>
            <MoneyOffIcon style={{ color: "#474e74", fontSize: 90 }} />
            <br /> You have no debts
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}
