import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import GameStatus from "@homegame/common/dist/enum/game-status.enum";
import { Link } from "react-router-dom";
import TabInfo from "./tab-info";
import GameTabsStyles from "./styles";
import TabPlayers from "./tab-players";
import TransactionType from "@homegame/common/dist/enum/transaction-type.enum";
import TabTransactions from "./tab-transactions";
import useRouteMatch from "../../core/hooks/use-route-match";
import { useGame } from "../game.context";

export default function GamePageTabsComponent() {
  const classes = GameTabsStyles();

  const {
    game,
    isCashier,
    isNew,
    isOngoing,
    isFinished,
    isCanceled,
    isSuperAdmin,
    reloadGame,
  } = useGame();

  const routeMatch = useRouteMatch([
    "/game/:gameId/stats",
    "/game/:gameId/info",
    "/game/:gameId/players",
    "/game/:gameId/buyins",
    "/game/:gameId/cashouts",
  ]);

  if (!game) return <></>;

  const currentTab =
    routeMatch?.pattern?.path ||
    `/game/:gameId/${game.status === GameStatus.FINISHED ? "stats" : "info"}`;

  const TabsComponent = () => (
    <Tabs value={currentTab} style={{ minHeight: 30, width: "100%" }} centered>
      {(isOngoing || isNew) && (
        <Tab
          className={classes.tabBtn}
          label="Info"
          value="/game/:gameId/info"
          to={`/game/${game.id}/info`}
          component={Link}
        />
      )}
      {
        <Tab
          className={classes.tabBtn}
          label="Players"
          value="/game/:gameId/players"
          to={`/game/${game.id}/players`}
          component={Link}
        />
      }
      {(isCashier || isSuperAdmin) && (
        <Tab
          className={classes.tabBtn}
          label="Buy ins"
          value="/game/:gameId/buyins"
          to={`/game/${game.id}/buyins`}
          component={Link}
        />
      )}
      {!isNew && (isCashier || isSuperAdmin) && (
        <Tab
          className={classes.tabBtn}
          label="Cash outs"
          value="/game/:gameId/cashouts"
          to={`/game/${game.id}/cashouts`}
          component={Link}
        />
      )}
      {isFinished && (
        <Tab
          className={classes.tabBtn}
          label="Stats"
          value="/game/:gameId/stats"
          to={`/game/${game.id}/stats`}
          component={Link}
        />
      )}
    </Tabs>
  );

  return (
    <>
      {!isCanceled && <TabsComponent />}
      {!currentTab ||
        (["/game/:gameId/stats", "/game/:gameId/info"].includes(currentTab) && (
          <TabInfo />
        ))}

      {!!currentTab && ["/game/:gameId/players"].includes(currentTab) && (
        <TabPlayers />
      )}

      {!!currentTab && ["/game/:gameId/buyins"].includes(currentTab) && (
        <TabTransactions
          type={TransactionType.BUY_IN}
          game={game}
          reloadGame={reloadGame}
        />
      )}
      {!!currentTab && ["/game/:gameId/cashouts"].includes(currentTab) && (
        <TabTransactions
          type={TransactionType.CASH_OUT}
          game={game}
          reloadGame={reloadGame}
        />
      )}
    </>
  );
}
