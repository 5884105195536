import { useMutation, useQuery } from "@tanstack/react-query";
import { FC, useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import {
  DataGrid,
  GridColDef,
  GridPaginationModel,
  GridRow,
} from "@mui/x-data-grid";
import { BaseServiceInterface } from "../service/base.service";
import InlineLoadingComponent from "./inline-loading.component";
import { useToast } from "../hooks/use-toast.hook";
import { usePullToRefresh } from "../context/pull-to-refresh.context";

const DEFAULT_TABLE_ITEMS_COUNT = 10;

export interface TableComponentHandles {
  triggerRefresh?: () => void;
}

interface TableComponentProps<I> {
  service: BaseServiceInterface<I>;
  columns: GridColDef[];
  checkboxSelection?: boolean;
  disableColumnMenu?: boolean;
  disableColumnSelector?: boolean;
  disableColumnFilter?: boolean;
  filters: { [key: string]: any };
  title?: string;
  onRowClick?: (row: any) => void;
  height?: number | string;
  className?: string;
  refresh?: any;
}

const CustomGridRow = (props: any) => {
  return <GridRow {...props} data-test-id="test-row" />;
};

const TableComponent: FC<TableComponentProps<any>> = ({
  filters,
  service,
  columns,
  disableColumnMenu,
  disableColumnSelector,
  disableColumnFilter,
  checkboxSelection,
  onRowClick,
  height,
  className,
  refresh = "",
}) => {
  const [tableFilters, setTableFilters] = useState<{ [key: string]: any }>({
    ...filters,
    searchString: "",
    take: filters.take || DEFAULT_TABLE_ITEMS_COUNT,
    skip: 0,
  });

  const [items, setItemsList] = useState<any[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const { showToast } = useToast();
  const { setRefreshFunction } = usePullToRefresh();

  const { isFetching, error, refetch, isError, data } = useQuery<
    [any[], number],
    Error
  >({
    queryKey: [`table${service}`, tableFilters],
    queryFn: () => service.list(tableFilters),
    initialData: [[], 0],
  });

  useEffect(() => {
    const refreshData = async () => {
      await refetch();
    };
    setRefreshFunction(refreshData);
    return () => {
      setRefreshFunction();
    };
  }, [setRefreshFunction]);

  useMutation({
    onError: (error: Error) => {
      showToast(error.message, "error");
    },
  });

  useEffect(() => {
    if (refresh) refetch();
  }, [refresh]);

  useEffect(() => {
    if (data && data.length > 0 && data[0]) {
      setItemsList(data[0]);
      setTotalCount(data[1]);
    }
  }, [data]);

  const handlePaginationModelChange = (
    updatedPaginationModel: GridPaginationModel
  ) => {
    setTableFilters({
      ...tableFilters,
      take: updatedPaginationModel.pageSize,
      skip: updatedPaginationModel.page * updatedPaginationModel.pageSize,
    });
  };

  // Filtering
  useEffect(() => {
    setTableFilters({ ...tableFilters, ...filters });
  }, [filters]);

  const NoItemsText = () => {
    const noItemsText =
      tableFilters?.searchString && tableFilters?.searchString.length > 0
        ? `No ${service.baseEndpoint} found with name like ${filters.searchString}`
        : `No ${service.baseEndpoint} yet...`;
    return (
      <>
        <img
          alt="home game manager loader"
          width="100"
          height="100"
          src="/cards.svg"
          style={{
            filter: "opacity(0.3)",
            margin: "0 auto",
            display: "block",
            width: "auto",
            height: "25svh",
          }}
        />
        <Typography textAlign="center" fontSize={14} display="block">
          {noItemsText}
        </Typography>
      </>
    );
  };

  if (isError) {
    return (
      <>
        <div>Error occurred: {error?.message}</div>
      </>
    );
  }

  return (
    <DataGrid
      data-test-id="test-table"
      className={className}
      density="comfortable"
      autoHeight={!height}
      initialState={{
        pagination: {
          paginationModel: {
            pageSize: tableFilters.take,
          },
        },
      }}
      rowCount={totalCount}
      loading={isFetching}
      columnHeaderHeight={0}
      rows={items}
      getRowHeight={() => "auto"}
      columns={columns}
      onPaginationModelChange={handlePaginationModelChange}
      pageSizeOptions={[tableFilters.take]}
      getRowSpacing={() => ({ top: 0, bottom: 10 })}
      paginationMode="server"
      disableDensitySelector
      disableRowSelectionOnClick
      disableColumnMenu={disableColumnMenu}
      disableColumnSelector={disableColumnSelector}
      disableColumnFilter={disableColumnFilter}
      checkboxSelection={checkboxSelection}
      filterMode="server"
      sortingMode="server"
      slots={{
        noRowsOverlay: NoItemsText,
        loadingOverlay: InlineLoadingComponent,
        row: CustomGridRow,
      }}
      hideFooterSelectedRowCount
      showColumnVerticalBorder={false}
      showCellVerticalBorder={false}
      onRowClick={(row) => {
        if (onRowClick) {
          onRowClick(row);
        }
      }}
      hideFooter={!items.length}
      sx={{
        height,
        width: "100%",
        "& .MuiDataGrid-overlayWrapper": {
          height: "200px !important",
          position: "absolute",
          top: 0,
          left: 0,
        },
      }}
    />
  );
};

export default TableComponent;
