import { useState } from "react";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { FormHelperText } from "@mui/material";
import PhoneInputComponent from "./phone-input.component";
import TableService from "../service/table.service";
import { Controller, useForm } from "react-hook-form";
import LoadingButton from "./loading-button.component";
import { useToast } from "../hooks/use-toast.hook";

const useStyles = makeStyles(() =>
  createStyles({
    title: {
      color: "#fff",
      fontWeight: 300,
      textAlign: "center",
    },
    form: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      padding: "10px 27px 15px 27px",
    },
    btn: { width: 200, marginTop: 15 },
  })
);

function SendInviteComponent({
  onSend,
  tableId,
}: {
  tableId: number;
  onSend?: ({ phone }: { phone: string }) => void;
}) {
  const classes = useStyles({});
  const tableService = new TableService();
  const [isCodeSending, setIsCodeSending] = useState<boolean>(false);
  const { showToast } = useToast();

  const {
    handleSubmit,
    control,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({ defaultValues: { phone: "" } });

  const onSubmit = async ({ phone }: { phone: string }) => {
    setIsCodeSending(true);
    try {
      await tableService.invitePlayer(tableId, phone);
      if (onSend) onSend({ phone });
      showToast("Invite sent!", "success");
    } catch (e) {
      const { message } = e as Error;
      showToast(message, "error");
    } finally {
      setIsCodeSending(false);
    }
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      noValidate
      autoComplete="off"
      className={classes.form}
    >
      <Controller
        name="phone"
        control={control}
        rules={{
          required: "Field is required",
        }}
        render={({ field }) => (
          <PhoneInputComponent
            autofocus={true}
            showLabel={false}
            {...field}
            onValidate={(isValid) => {
              if (isValid && errors.phone) clearErrors("phone");
              if (!isValid && !errors.phone)
                setError(
                  "phone",
                  {
                    type: "isPhoneValid",
                    message: "Phone number is not valid",
                  },
                  { shouldFocus: true }
                );
            }}
          />
        )}
      />
      {errors.phone && (
        <FormHelperText error>{errors.phone.message}</FormHelperText>
      )}

      <LoadingButton
        isLoading={isCodeSending}
        text="Send Invite"
        className={classes.btn}
        color="secondary"
      />
    </form>
  );
}

export default function InviteToTableDialog({
  onClose,
  tableId,
}: {
  onClose?: () => any;
  tableId: number;
}) {
  const classes: any = useStyles({});

  const handleClose = () => {
    if (onClose) onClose();
  };

  return (
    <Dialog
      PaperProps={{ style: { padding: 10, alignItems: "center" } }}
      onClose={handleClose}
      open={true}
    >
      <DialogTitle id="invite-dialog-title" className={classes.title}>
        Invite player
      </DialogTitle>

      <SendInviteComponent tableId={tableId} onSend={handleClose} />
    </Dialog>
  );
}
