import dayjs from "dayjs";
import { useCallback, useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import Grid from "@mui/material/Grid";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ClearIcon from "@mui/icons-material/Clear";
import Button from "@mui/material/Button";
import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  ChartData,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from "chart.js";
import ButtonGroup from "@mui/material/ButtonGroup";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import IconButton from "@mui/material/IconButton";
import {
  DateRange,
  DateRangePicker,
} from "../core/components/date-range-picker";
import BasePage from "../core/base.page";
import HeadingComponent from "../core/components/heading.component";
import { defaultRanges } from "../core/components/date-range-picker/defaults";
import { useAuth } from "../core/context/auth.context";
import StatsService from "../core/service/stats.service";
import Typography from "@mui/material/Typography";

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  Title
);
ChartJS.defaults.color = "#fff";
ChartJS.defaults.borderColor = "#8c8b8b";

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      maxWidth: 500,
      margin: "0 auto",
      textAlign: "center",
      paddingBottom: 120,
    },
    btn: {
      border: "1px solid #fff",
      color: "#fff",
      "& .MuiButtonBase-root": { color: "#fff" },
      "& .MuiButton-root": {
        color: "#fff",
        fontSize: 13,
        textTransform: "none",
        "& .MuiSvgIcon-root": {
          marginRight: 10,
          marginLeft: 5,
        },
      },
    },
  })
);

export default function StatsPage() {
  const classes = useStyles({});

  const statsService = new StatsService();

  const { user } = useAuth();

  const [transactionsData, setTransactionsData] = useState<
    ChartData<"bar"> | undefined
  >();

  const [open, setOpen] = useState(false);
  const [dateRange, setDateRange] = useState<DateRange>(defaultRanges[2]);

  const toggle = () => setOpen(!open);

  const getDatesString = () => {
    const { label, startDate, endDate } = dateRange;
    if (label) return label;
    if (!startDate || !endDate) return "Select date";
    return `${startDate.format("DD MMM")} - ${endDate.format("DD MMM")}`;
  };

  const prevRange = () => {
    const { startDate, endDate } = dateRange;
    if (!startDate || !endDate) return;
    const daysDiff = startDate!.diff(endDate, "days");
    setDateRange({
      startDate: startDate.subtract(daysDiff, "days"),
      endDate: endDate.subtract(daysDiff, "days"),
    });
  };

  const nextRange = () => {
    const { startDate, endDate } = dateRange;
    if (!startDate || !endDate) return;
    const daysDiff = startDate!.diff(endDate, "days");
    setDateRange({
      startDate: startDate.add(daysDiff, "days"),
      endDate: endDate.add(daysDiff, "days"),
    });
  };

  const changeRange = (range: DateRange) => {
    setDateRange(range);
    toggle();
  };

  const clearDate = () => {
    setDateRange(defaultRanges[2]);
  };

  const getTransactions = useCallback(
    async ({ dateFrom, dateTo }: { dateFrom: string; dateTo: string }) => {
      const res = await statsService.userStatByWinLoseMoney(user!.id!, {
        dateFrom,
        dateTo,
      });
      if (!res.length) return;

      setTransactionsData({
        labels: res.map((r) => dayjs(r.date).format("D MMM HH:mm")),
        datasets: [
          {
            label: "Won",
            data: res
              .filter((t) => t.type === "won")
              .map((t) => Math.abs(t.value)),
            backgroundColor: "#66bb6a",
          },
          {
            label: "Lose",
            data: res
              .filter((t) => t.type === "lose")
              .map((t) => Math.abs(t.value)),
            backgroundColor: "#ef5350",
          },
        ],
      });
    },
    [dateRange]
  );

  useEffect(() => {
    if (!dateRange.startDate || !dateRange.endDate) return;
    const startDate = dateRange.startDate.startOf("day");
    const endDate = dateRange.endDate.endOf("day");
    getTransactions({
      dateFrom: startDate.format(),
      dateTo: endDate.format(),
    }).then();
  }, [dateRange]);

  return (
    <BasePage>
      <Grid
        container
        spacing={0}
        direction="row"
        alignItems="flex-start"
        className={classes.container}
      >
        <Grid item xs={12}>
          <HeadingComponent text="Stats" />
        </Grid>
        <Grid item sm={12} xs={12}>
          <ButtonGroup variant="outlined" aria-label="text button group">
            <Button onClick={prevRange} className={classes.btn}>
              <ChevronLeftIcon sx={{ fontSize: 17 }} />
            </Button>
            <div className={classes.btn}>
              <Button variant="text" onClick={() => setOpen(true)}>
                {/*<p>*/}
                <CalendarMonthIcon sx={{ fontSize: 17 }} />
                {getDatesString()}
                {/*</p>*/}
              </Button>
              <IconButton
                onClick={clearDate}
                color="primary"
                aria-label="upload picture"
                component="span"
              >
                <ClearIcon
                  style={{ position: "relative", zIndex: 9 }}
                  sx={{ fontSize: 17 }}
                />
              </IconButton>
            </div>
            <Button onClick={nextRange} className={classes.btn}>
              <ChevronRightIcon sx={{ fontSize: 17 }} />
            </Button>
          </ButtonGroup>

          <br />
          <br />
        </Grid>

        <Grid item xs={12}>
          {transactionsData &&
            transactionsData.labels &&
            transactionsData.labels.length > 0 && (
              <Bar data={transactionsData} options={{ color: "#fff" }} />
            )}
          {transactionsData?.labels?.length === 0 && (
            <>
              <img
                alt="home game manager loader"
                width="100"
                height="100"
                src="/cards.svg"
                style={{
                  filter: "opacity(0.3)",
                  margin: "0 auto",
                  display: "block",
                  width: "auto",
                  height: "25svh",
                }}
              />
              <Typography>No data for selected period</Typography>
            </>
          )}
        </Grid>
      </Grid>
      <DateRangePicker open={open} toggle={toggle} onChange={changeRange} />
    </BasePage>
  );
}
