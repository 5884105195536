import { useRef } from "react";
import Grid from "@mui/material/Grid";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import BasePage from "../core/base.page";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import useRouteMatch from "../core/hooks/use-route-match";
import { Link } from "react-router-dom";
import DebtsTab from "./tabs/debts-tab";
import TabReceived from "./tabs/tab-received";
import TabAllPayments from "./tabs/tab-all-payments";

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      maxWidth: 500,
      margin: "0 auto",
      height: "100%",
      alignContent: "flex-start",
    },
  })
);

export default function TransactionsPage() {
  const classes = useStyles({});
  const tableRef = useRef<HTMLInputElement>(null);

  const routeMatch = useRouteMatch([
    "/transactions/debts",
    "/transactions/received_payments",
    "/transactions/all_transactions",
  ]);

  const currentTab = routeMatch?.pattern?.path || "/transactions/debts";

  return (
    <BasePage>
      <Grid container className={classes.container} ref={tableRef}>
        <Grid item xs={12} height={48}>
          <Tabs value={currentTab} centered aria-label="basic tabs example">
            <Tab
              label="Debts"
              value="/transactions/debts"
              to="/transactions/debts"
              component={Link}
            />
            <Tab
              label="Received"
              value="/transactions/received_payments"
              to="/transactions/received_payments"
              component={Link}
            />
            <Tab
              label="All"
              value="/transactions/all_transactions"
              to="/transactions/all_transactions"
              component={Link}
            />
          </Tabs>
        </Grid>
        {!!currentTab && ["/transactions/debts"].includes(currentTab) && (
          <DebtsTab />
        )}

        {!!currentTab &&
          ["/transactions/received_payments"].includes(currentTab) && (
            <TabReceived />
          )}

        {!!currentTab &&
          ["/transactions/all_transactions"].includes(currentTab) && (
            <TabAllPayments />
          )}
      </Grid>
    </BasePage>
  );
}
