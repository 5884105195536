import BaseService from "./base.service";
import TransactionInterface from "@homegame/common/dist/interface/transaction.interface";
import TablePlayerInterface from "@homegame/common/dist/interface/table-player.interface";

interface StatsProps {
  dateFrom?: string;
  dateTo?: string;
  take?: number;
  skip?: number;
}

export default class StatsService extends BaseService {
  baseEndpoint = "stats";

  async userStatByTables(
    userId: number,
    params: StatsProps
  ): Promise<TransactionInterface[]> {
    return super.request({
      url: `${this.baseEndpoint}/tables/${userId}`,
      params,
    });
  }

  async userStatByGames(
    userId: number,
    params: StatsProps
  ): Promise<[TablePlayerInterface[], number]> {
    return super.request({
      url: `${this.baseEndpoint}/games/${userId}`,
      params,
    });
  }

  async userStatByGamesInTables(
    userId: number,
    { dateFrom, dateTo }: StatsProps
  ): Promise<[string, number][]> {
    return super.request({
      url: `${this.baseEndpoint}/games-tables/${userId}`,
      params: { dateTo, dateFrom },
    });
  }

  async userStatByTransactions(
    userId: number,
    { dateFrom, dateTo }: StatsProps
  ): Promise<TransactionInterface[]> {
    return super.request({
      url: `${this.baseEndpoint}/transactions/${userId}`,
      params: { dateTo, dateFrom },
    });
  }

  async userStatByWinLoseMoney(
    userId: number,
    { dateFrom, dateTo }: StatsProps
  ): Promise<{ type: string; value: number; date: string }[]> {
    const { data } = await super.request<{
      data: { type: string; value: number; date: string }[];
    }>({
      url: `${this.baseEndpoint}/win-lose/${userId}`,
      params: { dateTo, dateFrom },
    });
    return data;
  }
}
