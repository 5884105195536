import UserInterface from "@homegame/common/src/interface/user.interface";
import UserFilterInterface from "@homegame/common/src/interface/filters/user-filter.interface";
import BaseService from "./base.service";
import { axios } from "../api";
import { API_URL } from "../config";
import TransactionInterface from "@homegame/common/dist/interface/transaction.interface";
import TableInterface from "@homegame/common/dist/interface/table.interface";
import GameInterface from "@homegame/common/dist/interface/game.interface";
import TransactionStatus from "@homegame/common/dist/enum/transaction-status.enum";

export interface UserDebtInterface {
  game: GameInterface;
  table: TableInterface;
  debt: number;
  payedDebt: number;
  pendingPayedDebt: number;
  debtTo?: UserInterface;
  payedDate?: Date;
  status?: TransactionStatus;
}

export default class UserService extends BaseService {
  baseEndpoint = "users";

  async list(params?: UserFilterInterface): Promise<[UserInterface[], number]> {
    return this.baseList(params);
  }

  async single(id: string | number | undefined) {
    return super.baseSingle<UserInterface>(id);
  }

  async getDebts(userId: number | string): Promise<UserDebtInterface[]> {
    return this.request({
      method: "get",
      url: `${this.baseEndpoint}/${userId}/debts`,
      transformResponse: [
        (data) => {
          let resp;

          try {
            resp = JSON.parse(data);
          } catch (error) {
            throw Error(
              `[requestClient] Error parsing response JSON data - ${JSON.stringify(
                error
              )}`
            );
          }

          if (resp.status === "success") {
            return resp.data;
          } else {
            throw Error(
              `[requestClient] Request failed with reason -  ${data}`
            );
          }
        },
      ],
    });
  }

  async getIncomingTransactions(
    userId: number | string
  ): Promise<[TransactionInterface[], number]> {
    return this.request({
      method: "get",
      url: `${this.baseEndpoint}/${userId}/incoming-payments`,
    });
  }

  async save<UserInterface>(
    userData: Partial<UserInterface>,
    id: number | string | undefined | null
  ): Promise<UserInterface> {
    if (id) {
      try {
        return axios.put(`${API_URL}${this.baseEndpoint}/${id}`, userData);
      } catch (e) {
        console.error(e);
        throw BaseService.handleError(e);
      }
    }
    return this.baseCreate(userData);
  }

  async invite(data: { email: string; tableId: string }): Promise<void> {
    try {
      await axios.post(`${API_URL}${this.baseEndpoint}/invite`, data);
    } catch (e) {
      throw BaseService.handleError(e);
    }
  }
}
