import { useCallback, useEffect, useState } from "react";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import { NumericFormat } from "react-number-format";
import TextField from "@mui/material/TextField";
import TransactionType from "@homegame/common/dist/enum/transaction-type.enum";
import FormControl from "@mui/material/FormControl";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { capitalize } from "@mui/material";
import Button from "@mui/material/Button";
import FormHelperText from "@mui/material/FormHelperText";
import UserInterface from "@homegame/common/dist/interface/user.interface";
import GameInterface from "@homegame/common/dist/interface/game.interface";
import TransactionService from "../service/transaction.service";
import UserService from "../service/user.service";
import { useAuth } from "../context/auth.context";
import SelectUserComponent from "./select-user.component";
import { Controller, useForm } from "react-hook-form";
import TransactionStatus from "@homegame/common/dist/enum/transaction-status.enum";
import InputAdornment from "@mui/material/InputAdornment";
import useGameInfo from "../hooks/useGameInfo/use-game-info.hook";
import LoadingButton from "./loading-button.component";
import getPlayerLastTransaction from "../hooks/useGameInfo/get-player-last-transaction";
import { useToast } from "../hooks/use-toast.hook";

const useStyles = makeStyles(() =>
  createStyles({
    title: {
      color: "#fff",
      fontWeight: 300,
      textAlign: "center",
    },
    form: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      padding: "10px 27px 15px 27px",
    },
    btn: { width: 200, marginTop: 15 },
    btnAccept: { width: 200, marginTop: 15, backgroundColor: "green" },
    moneyInput: {
      color: "#fff",
      "& .MuiTypography-root, & .Mui-disabled": {
        color: "#fff !important",
        "-webkit-text-fill-color": "#fff",
      },
    },
    errorText: {
      backgroundColor: "red",
      color: "#fff !important",
      fontSize: 12,
      textShadow: "none",
      padding: "5px 15px",
      borderRadius: 5,
      marginLeft: 0,
    },
  })
);

export default function CreateTransactionDialog({
  onClose,
  game,
  type,
  users,
  toUser,
  open = true,
  fromUser,
  amount = 0,
}: {
  users?: UserInterface[];
  onClose?: () => any;
  game?: GameInterface;
  type: TransactionType;
  toUser?: UserInterface;
  fromUser?: UserInterface;
  open?: boolean;
  amount?: number;
}) {
  const classes: any = useStyles({});
  const transactionService = new TransactionService();
  const userService = new UserService();
  const { showToast } = useToast();
  const { user } = useAuth();
  const [usersList, setUsersList] = useState<UserInterface[]>(users || []);

  const [isTransactionCreating, setIsTransactionCreating] =
    useState<boolean>(false);

  const { currentChipsVal, playersGameStats, currentMoneyVal } =
    useGameInfo(game);
  const totalGameChips = currentChipsVal;

  let defaultChipsAmount = type !== TransactionType.PAYMENT ? "" : "0";

  const playerCashOuts =
    game?.transactions
      ?.filter(
        (p) =>
          p.toUserId === fromUser?.id && p.type === TransactionType.CASH_OUT
      )
      .sort((a, b) => {
        if (!a.createdAt || !b.createdAt) return 0;
        return (
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        );
      }) || [];

  if (type === TransactionType.BUY_IN) {
    if (playerCashOuts.length > 0) {
      defaultChipsAmount = String(playerCashOuts[0].chips);
    }
  }

  const {
    trigger,
    handleSubmit,
    control,
    formState: { errors },
    watch,
    setValue,
  } = useForm({
    mode: "onChange",
    values: {
      fromUser: fromUser,
      toUser: toUser,
      chips: defaultChipsAmount,
      amount:
        amount > 0
          ? String(amount)
          : game
          ? String(
              Number(defaultChipsAmount) * (game.moneyValue / game.chipValue)
            )
          : defaultChipsAmount,
      tableId: game?.tableId,
      gameId: game?.id,
      type,
      status: TransactionStatus.PENDING,
    },
  });

  const chipsValue = watch("chips");

  useEffect(() => {
    // Return early if game data is not available
    if (!game) return;

    const chipsRate = game.moneyValue / game.chipValue;

    if (type === TransactionType.BUY_IN) {
      // Calculate and set the amount value based on chipsValue for buy in transactions
      setValue("amount", String(Number(chipsValue) * chipsRate));
      trigger("amount");
      return;
    }

    if (type === TransactionType.CASH_OUT) {
      // Calculate the amount value for cash out transactions
      const playerInfo = toUser
        ? playersGameStats.find((p) => p.id === toUser.id)
        : undefined;
      const chipsPrice = Number(chipsValue) * chipsRate;
      const moneyAmount =
        chipsPrice - (playerInfo ? playerInfo.debtToTable : 0);

      // Set the amount value as the calculated money amount, ensuring it is not negative
      setValue("amount", String(moneyAmount > 0 ? moneyAmount : 0));
      trigger("amount");
    }
  }, [chipsValue]);

  const loadUsers = useCallback(async () => {
    try {
      const [items] = await userService.list({
        forTransaction: true,
        excludeSelf: true,
      });
      setUsersList(items);
    } catch (e) {
      console.error(e);
    }
  }, []);

  useEffect(() => {
    if (!game) loadUsers().then();
  }, []);

  const onSubmit = async (data: any) => {
    if (!game) return;
    try {
      if (data.chips < 0) {
        showToast("Chips amount must be greater than 0!", "warning");
        return;
      }

      if (type === TransactionType.CASH_OUT) {
        if (data.chips > totalGameChips) {
          showToast(
            "You cannot return more chips than total in game",
            "warning"
          );
          return;
        }
      }

      setIsTransactionCreating(true);
      await transactionService.save({
        ...data,
        fromUserId: data.fromUser!.id,
        toUserId: data.toUser!.id,
      });
      if (onClose) onClose();
      const successMessage =
        type === TransactionType.PAYMENT
          ? "Payment sent!"
          : `${
              type === TransactionType.CASH_OUT ? "Cash out" : "Buy in"
            } added!`;
      showToast(successMessage, "success");
    } catch (e: any) {
      const { message } = e as Error;
      showToast(message, "error");
    } finally {
      setIsTransactionCreating(false);
    }
  };

  const handleClose = () => {
    if (onClose) onClose();
  };

  if (!user || !game) return <p>Error</p>;

  const lastToPlayerTransaction = getPlayerLastTransaction(
    toUser?.id,
    game.transactions
  );

  /**
   * Validates the value of chips for a transaction.
   *
   * @param {string} value - The value of chips to validate.
   * @returns {boolean | string} Returns true if the value is valid. Otherwise, returns an error message.
   */
  const chipsValueValidate = (value: string): boolean | string => {
    // Return true if the game data is not defined
    if (!game) return true;

    // Check if the value is a number
    const parsedValue = parseInt(value);
    if (isNaN(parsedValue)) return "Wrong value";

    // Validate the value based on the transaction type
    if (type === TransactionType.CASH_OUT) {
      // For cash out transactions, the value cannot be more than the total chips in the game
      if (parsedValue > totalGameChips)
        return "You cannot return more chips than total in game";
    } else if (type === TransactionType.BUY_IN) {
      const minimumAllowed = game.minimumBuyIn;
      const maximumAllowed = game.maximumBuyIn || 0;
      // Ensure the value is within the allowed range for buy-ins
      if (parsedValue < minimumAllowed) {
        // check if user already have BUY IN and allow him to create buy in without money
        if (
          lastToPlayerTransaction &&
          lastToPlayerTransaction.type === TransactionType.BUY_IN
        ) {
          return true;
        }
        return `The buy-in amount must be at least ${minimumAllowed} chips`;
      }
      if (game.maximumBuyIn && parsedValue > maximumAllowed) {
        return `The buy-in amount cannot exceed ${maximumAllowed} chips`;
      }

      if (playerCashOuts.length > 0 && playerCashOuts[0].chips > parsedValue)
        return "Buy in must be greater or equal than last cash out";
    }

    return true;
  };

  const moneyValueValidate = (value: string) => {
    if (!game) return true;
    if (isNaN(parseInt(value))) return "Wrong value";
    const parsedValue = parseInt(value);

    switch (type) {
      case TransactionType.BUY_IN:
        if (
          !toUser ||
          !lastToPlayerTransaction ||
          lastToPlayerTransaction.type !== TransactionType.CASH_OUT
        )
          return true;

        const { amount, currency } = lastToPlayerTransaction;
        const absoluteAmount = Math.abs(amount);
        if (absoluteAmount > parsedValue) {
          return `User cashed out of game with ${absoluteAmount} ${currency}, a buy in after cash out must be at least ${absoluteAmount} ${currency}`;
        }
        break;
      case TransactionType.CASH_OUT:
        // Direct comparison without redundant conversion
        if (parsedValue > currentMoneyVal) {
          return `Cannot cash out more than money left in game (${currentMoneyVal} ${game.currency})`;
        }
        break;
      default:
        return true;
    }
    return true;
  };

  return (
    <Dialog
      fullWidth
      onClose={handleClose}
      aria-labelledby="invite-dialog-title"
      open={open}
    >
      <DialogTitle id="invite-dialog-title" className={classes.title}>
        {capitalize(type)}
      </DialogTitle>

      <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
        <FormControl
          fullWidth
          style={{ display: !!fromUser ? "none" : "inline-block" }}
        >
          <Controller
            render={({ field }) => (
              <SelectUserComponent
                label="From player"
                {...field}
                disabled={!!fromUser || !game}
                list={game ? usersList : [user]}
              />
            )}
            name="fromUser"
            control={control}
            rules={{ required: "Field required" }}
          />
          {errors.fromUser && (
            <FormHelperText className={classes.errorText} error>
              {errors.fromUser.message}
            </FormHelperText>
          )}
        </FormControl>

        {type !== TransactionType.PAYMENT && (
          <FormControl fullWidth style={{ display: "inline-block" }}>
            <Controller
              render={({ field }) => (
                <NumericFormat
                  {...field}
                  customInput={TextField}
                  fullWidth
                  label="Chips"
                  allowNegative={false}
                  fixedDecimalScale={true}
                  variant="filled"
                  placeholder="Chips"
                  autoFocus={true}
                />
              )}
              name="chips"
              control={control}
              rules={{
                required: "Field required",
                validate: chipsValueValidate,
              }}
            />

            {errors.chips && (
              <FormHelperText className={classes.errorText} error>
                {errors.chips.message}
              </FormHelperText>
            )}
          </FormControl>
        )}

        <FormControl fullWidth className={classes.moneyInput}>
          <Controller
            render={({ field }) => (
              <NumericFormat
                {...field}
                customInput={TextField}
                fullWidth
                label="Money"
                allowNegative={true}
                allowLeadingZeros={true}
                fixedDecimalScale={true}
                variant="filled"
                placeholder="Money"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      {game?.currency}
                    </InputAdornment>
                  ),
                }}
              />
            )}
            name="amount"
            control={control}
            rules={{
              required: "Field required",
              validate: moneyValueValidate,
            }}
          />

          {errors.amount && (
            <FormHelperText className={classes.errorText} error>
              {errors.amount.message}
            </FormHelperText>
          )}
        </FormControl>

        <FormControl
          fullWidth
          style={{ display: !!toUser ? "none" : "inline-block" }}
        >
          <Controller
            render={({ field }) => (
              <SelectUserComponent
                label="To player"
                {...field}
                disabled={!!toUser}
                list={usersList}
              />
            )}
            name="toUser"
            control={control}
            rules={{ required: "Field required" }}
          />
          {/*// @ts-ignore*/}
          {errors.toUser && (
            <FormHelperText className={classes.errorText} error>
              {errors.toUser.message}
            </FormHelperText>
          )}
        </FormControl>

        <LoadingButton
          text={`${
            type === TransactionType.PAYMENT ? "Send" : "Approve"
          } ${type}`}
          type="submit"
          variant="contained"
          className={classes.btnAccept}
          isLoading={isTransactionCreating}
        />

        <Button
          className={classes.btn}
          onClick={handleClose}
          variant="contained"
          color="secondary"
        >
          Close
        </Button>
      </form>
    </Dialog>
  );
}
